<style>
.actions {
  align-items: center;
  display: none;
  flex-direction: row;
  position: absolute;
  right: 5px;
  top: 20px;
  width: calc(100% - 5px * 2);
}
</style>

<div class="actions">
  <slot></slot>
</div>

